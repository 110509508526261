/* src/index.css */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Remove default margin and padding */
body {
    margin: 0;
    padding: 0;
}

/* Set root font size */
html {
    font-size: 16px;
}

/* Set font family and color for all text */
body, input, select, textarea {
    font-family: 'Roboto', sans-serif;
    color: #333;
}

/* Set font family and color for headings */
h1, h2, h3, h4, h5, h6 {
    font-family: 'Raleway', sans-serif;
    color: #333;
}

/* Set global link styles */
a {
    color: #B60000;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.2s;
}

a:hover {
    color: #333;
}

/* Set styles for Material UI buttons */
button.MuiButton-root {
    border-radius: 0;
}

button.MuiButton-containedPrimary {
    background-color: #B60000;
    color: #fff;
}

button.MuiButton-containedPrimary:hover {
    background-color: #A40000;
}

/* Set styles for Formik inputs */
input.MuiInputBase-input {
    padding: 0.75rem 1rem;
    border-radius: 0;
    border: 2px solid #ccc;
}

input.MuiInputBase-input:focus {
    border-color: #B60000;
}

/* Set styles for Formik select inputs */
select.MuiSelect-root {
    padding: 0.75rem 1rem;
    border-radius: 0;
    border: 2px solid #ccc;
}

select.MuiSelect-root:focus {
    border-color: #B60000;
}

/* Set styles for Formik error messages */
.MuiFormHelperText-root.Mui-error {
    color: #f44336;
    margin-top: 0.25rem;
}

/* Set styles for Material UI table */
.MuiTable-root {
    border-collapse: collapse;
    width: 100%;
}

.MuiTable-head th, .MuiTableBody-root td {
    border: 2px solid #ccc;
    padding: 0.5rem;
}

.MuiTableBody-root tr:nth-child(even) {
    background-color: #f9f9f9;
}

.MuiTableFooter-root {
    border-top: 2px solid #ccc;
}

.MuiTableCell-root:last-child {
    text-align: right;
}

/* Set styles for custom classes */
.text-red {
    color: #B60000;
}

.bg-white {
    background-color: #fff;
}

.shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.carousel {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.slide {
    width: 100%;
    display: none;
}

.slide.active {
    display: block;
}

.carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: none;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 24px;
    cursor: pointer;
}

.carousel-control.left {
    left: 0;
}

.carousel-control.right {
    right: 0;
}

.red-background-div {
    background-image: url("assets/images/red-background-template.png");
    background-repeat: repeat-x;
    animation: flipBackground 15s linear infinite;
}

@keyframes flipBackground {
    0% {
        background-position: 100% 100%;
    }
    50% {
        background-position: 100% 0%;
        transform: scaleX(1);
    }
    100% {
        background-position: 80% 80%;
    }
}